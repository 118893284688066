<template>
	<a-modal title="编辑" :width="500" :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-row :gutter="24">
				<a-col :md="24" :sm="24">
					<a-form :form="form">
						<!-- 规格名称 -->
						<a-form-item label="参数名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
							<a-textarea placeholder="请输入" style="width: 340px" :maxlength="8" type="text"
								v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]" />
						</a-form-item>
					</a-form>
				</a-col>
				<a-col :md="24" :sm="24">
					<a-form :form="form">
						<!-- 规格名称 -->
						<a-form-item label="排序值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
							<a-input-number placeholder="请输入" style="width: 200px"
								v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]" />
						</a-form-item>
					</a-form>
				</a-col>
			</a-row>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		getParamsNameDetail,
		editParamsName
	} from '@/api/modular/mallLiving/paramsName/paramsName'

	export default {
		data() {
			return {
				loading: false,
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 6
					},
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					},
				},
				nameLength: 8,
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
				id: '',
				categoryId: ''
			}
		},

		methods: {
			// 初始化方法
			edit(record) {
				this.visible = true
				this.confirmLoading = true
				this.id = record.id
				//调取详情接口，回显数据
				setTimeout(() => {
					getParamsNameDetail({
						id: this.id
					}).then((res) => {
						if (res.success) {
							var details = res.data
							this.form.setFieldsValue({
								name: details.name,
								sort: details.sort
							})
							this.categoryId = details.categoryId
							this.confirmLoading = false
						}
					})
				}, 200)
			},
			//提交操作
			handleSubmit() {
				this.confirmLoading = true
				this.form.validateFields((errors, values) => {
					if (!errors) {
						editParamsName({
							id: this.id,
							categoryId: this.categoryId,
							...values
						}).then((res) => {
							if (res.success) {
								this.$message.success("编辑成功！")
								this.$emit("ok", values)
								this.confirmLoading = false
								this.handleCancel()
							} else {
								setTimeout(() => {
									this.confirmLoading = false
								}, 600)
							}
						})
					} else {
						this.confirmLoading = false
					}
				})
			},
			handleCancel() {
				this.visible = false //关闭对话框
				this.confirmLoading = false
				this.form.resetFields() //重置表单
			}
		},
	}
</script>
<style scoped>
	.flex {
		display: flex;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}
</style>
